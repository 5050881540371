import * as React from 'react';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { RelatedItemCard } from '@lp-root/src/components/mol.related-article-item/related-item-card.component';
import { ArticlePageData , ArticleDetail } from '@lp-root/src/components/org.article-detail/article-detail.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { MarkdownRemark, Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { PageProps } from '@lp-src/utils/local-types';
import { appPaths } from '@lp-src/utils/path';
import { Col, Grid, H2, Row, Separator } from '@web/atomic';
import { getDetailPageUrl , getCanonicalUrl } from '@web/utils/url';
import { Component } from 'react';
import { ProfileSelectionSection } from '../home/components/profile-selection-section.component';
import { graphql } from 'gatsby';

export interface ArticleDetailTemplateProps {
  id: string;
  related: string[];
  conversations: ArticleDetailConversation[];
}
export interface ArticleDetailConversation {
  title: string;
  subtitle: string;
  url: string;
}

interface CustomQuery extends Query {
  related: {
    nodes: MarkdownRemark[];
  };
}

class ArticleDetailTemplate extends Component<PageProps<ArticleDetailTemplateProps, Query>> {
  render(): JSX.Element {
    const data = this.props.data.markdownRemark;
    const siteUrl = this.props.data.site.siteMetadata.siteUrl;
    const conversations = this.props.pageContext.conversations;
    const related = (this.props.data as CustomQuery)?.related?.nodes;
    const hasRelatedArticles = !!related && related.length > 0;
    const hasRelatedConversations = !!conversations && conversations.length > 0;

    const pageData: ArticlePageData = {
      title: data.frontmatter.title,
      subtitle: data.frontmatter.description,
      headings: data.headings,
      publishedDate: parseToDate(data.frontmatter.date),
      htmlAst: data.htmlAst,
      timeToRead: data.timeToRead,
      hideTopImage: !data.frontmatter.showTopImage,
      hideTableOfContents: !data.frontmatter.showTableOfContents,
      seo: {
        title: data.frontmatter.seoTitle || data.frontmatter.title,
        keywords: data.frontmatter.keywords,
        description: data.frontmatter.seoDescription || data.excerpt,
        imageUrl: data.frontmatter.img,
        imageAlt: data.frontmatter.imgAlt,
        article: {
          published_time: parseToDate(data.frontmatter.date),
          autor: ['Vigilantes do Sono'],
          section: 'Artigos',
          tag: [],
        },
      },
    };

    return (
      <IndexLayout location={this.props.location}>
        <Grid>
          <ArticleDetail
            data={pageData}
            slug={this.props.pageContext.id}
            canonicalUrl={getCanonicalUrl(siteUrl, appPaths.blog.path, this.props.data.markdownRemark.frontmatter.path)}
          />

          {hasRelatedArticles && (
            <>
              <Row center="xs" mb>
                <Col xs={12} md={9} lg={7}>
                  <H2>Artigos Relacionados</H2>
                </Col>
              </Row>
              {related.map((relatedArticle) => (
                <RelatedItemCard
                  title={relatedArticle.frontmatter.seoTitle || relatedArticle.frontmatter.title}
                  description={relatedArticle.frontmatter.seoDescription || relatedArticle.excerpt}
                  image={{ url: relatedArticle.frontmatter.img, alt: relatedArticle.frontmatter.imgAlt }}
                  url={getDetailPageUrl(appPaths.blog.path, relatedArticle.frontmatter.path)}
                  key={relatedArticle.id}
                />
              ))}
              <Separator />
            </>
          )}
          {hasRelatedConversations && (
            <>
              <Row center="xs" mb>
                <Col xs={12} md={9} lg={7}>
                  <H2>Conversas Relacionadas</H2>
                </Col>
              </Row>
              {conversations.map((conversation) => (
                <RelatedItemCard
                  title={conversation.title}
                  description={conversation.subtitle}
                  url={conversation.url}
                  key={conversation.title}
                />
              ))}
              <Separator />
            </>
          )}
        </Grid>
        <ProfileSelectionSection />
      </IndexLayout>
    );
  }
}

export default ArticleDetailTemplate;

export const query = graphql`
  query ArticleDetailBySlug($id: String!, $related: [String!]!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      excerpt
      htmlAst
      headings {
        depth
        value
      }
      frontmatter {
        date
        path
        title
        description
        seoTitle
        seoDescription
        img
        imgAlt
        keywords
        showTopImage
        showTableOfContents
      }
    }

    related: allMarkdownRemark(filter: { frontmatter: { path: { in: $related } } }) {
      nodes {
        id
        timeToRead
        excerpt
        frontmatter {
          path
          seoTitle
          title
          seoDescription
          img
          imgAlt
        }
      }
    }
  }
`;
