import { BodySecondary, Row, Col, Divisor, Separator } from '@web/atomic';
import * as React from 'react';
import { LazyLoadImage } from '@web/atomic/legacy/atm.lazy-load-image';
import { RelatedItemCardTitleStyled, RelatedItemCardStyled } from './related-item-card.style';

interface RelatedArticleImage {
  url: string;
  alt: string;
}
interface RelatedArticleItemProps {
  title: string;
  description: string;
  image?: RelatedArticleImage;
  url: string;
}

export const RelatedItemCard: React.FunctionComponent<RelatedArticleItemProps> = (props) => {
  const hasImage = !!props.image?.url;
  return (
    <RelatedItemCardStyled to={props.url}>
      <Row center="xs" mt>
        {hasImage && (
          <Col xs={12} md={4} lg={3}>
            <LazyLoadImage src={props.image.url} alt={props.image.alt} fitWidth height={200} />
            <Separator />
          </Col>
        )}

        <Col xs={12} md={hasImage ? 5 : 9} lg={hasImage ? 4 : 7}>
          <RelatedItemCardTitleStyled>{props.title}</RelatedItemCardTitleStyled>
          <BodySecondary>{props.description} </BodySecondary>
          <Separator />
        </Col>
      </Row>
      <Row center="xs" mb>
        <Col xs={12} md={9} lg={7}>
          <Divisor />
        </Col>
      </Row>
    </RelatedItemCardStyled>
  );
};
