import styled from 'styled-components';
import { H3Style } from '@web/atomic';
import { Link } from 'gatsby';

export const RelatedItemCardTitleStyled = styled.p`
  ${H3Style}
`;

export const RelatedItemCardStyled = styled(Link)`
  :hover {
    ${RelatedItemCardTitleStyled} {
      text-decoration: underline;
    }
  }
`;
